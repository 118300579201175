/* eslint-disable no-param-reassign */
import authCheck from './auth-check'

const darkThemeMeta = { headerTheme: 'dark' }

const routes = [
  {
    name: 'ViewSelectWorkspace',
    path: '/select-workspace',
    meta: darkThemeMeta,
    component: () => import(/* webpackChunkName: 'view-select-workspace' */ '@/components/views/ViewSelectWorkspace'),
  },
  {
    name: 'ViewChoosePlan',
    path: '/choose-plan',
    meta: darkThemeMeta,
    component: () => import(/* webpackChunkName: 'view-choose-plan' */ '@/components/views/price-plans/ViewPricePlans'),
  },
]

const stripeRoutes = [
  {
    name: 'ViewCheckoutComplete',
    path: '/checkout-complete',
    component: () => import(/* webpackChunkName: 'view-checkout' */ '@/components/views/checkout/ViewCheckoutComplete'),
  },
  {
    name: 'ViewUpdatePaymentMethodComplete',
    path: '/update-payment-method-complete',
    component: () =>
      import(
        /* webpackChunkName: 'view-update-payment-method-complete' */ '@/components/views/checkout/ViewUpdatePaymentMethodComplete'
      ),
  },
  {
    name: 'ViewSinglePurchaseComplete',
    path: '/single-purchase-complete',
    component: () =>
      import(
        /* webpackChunkName: 'view-single-purchase-complete' */ '@/components/views/checkout/ViewSinglePurchaseComplete'
      ),
  },
]

const myPageRoutes = [
  {
    name: 'ViewAddWorkspace',
    path: '/my-page/add-workspace',
    meta: darkThemeMeta,
    component: () =>
      import(
        /* webpackChunkName: 'view-add-workspace' */
        '@/components/views/my-page/ViewAddWorkspace'
      ),
  },
  {
    name: 'ViewPlanAndBilling',
    path: '/my-page/plan-and-billing',
    meta: darkThemeMeta,
    component: () =>
      import(
        /* webpackChunkName: 'view-plan-and-billing' */
        '@/components/views/my-page/plan-and-billing/ViewPlanAndBilling'
      ),
  },
  {
    name: 'ViewManagePlan',
    path: '/my-page/manage-plan',
    meta: darkThemeMeta,
    component: () =>
      import(
        /* webpackChunkName: 'view-manage-plan' */
        '@/components/views/my-page/ViewManagePlan'
      ),
  },
  {
    name: 'ViewManageMembers',
    path: '/manage-members',
    component: () =>
      import(
        /* webpackChunkName: 'view-manage-members' */
        '@/components/views/manage-members/ViewManageMembers'
      ),
  },
  {
    name: 'ViewMyAccount',
    path: '/my-account',
    component: () =>
      import(
        /* webpackChunkName: 'view-my-account' */
        '@/components/views/my-account/ViewMyAccount'
      ),
  },
]

const myAssetsRoutes = [
  {
    name: 'ViewPurchased',
    path: '/purchased',
    meta: darkThemeMeta,
    component: () => import(/* webpackChunkName: 'view-purcahsed' */ '@/components/views/my-collection/ViewPurchased'),
  },
  {
    name: 'ViewPinnedCollections',
    path: '/pinned',
    meta: darkThemeMeta,
    component: () =>
      import(
        /* webpackChunkName: 'view-pinned-collections' */ '@/components/views/my-collection/ViewPinnedCollections'
      ),
  },
  {
    name: 'ViewPinnedCollection',
    path: '/pinned/:id',
    meta: darkThemeMeta,
    component: () =>
      import(/* webpackChunkName: 'view-pinned-collection' */ '@/components/views/my-collection/ViewPinnedCollection'),
  },
]

const digitizationOrdersRoutes = [
  {
    name: 'ViewDigitizationOrder',
    path: '/digitization-orders/:id',
    component: () =>
      import(
        /* webpackChunkName: 'view-digitization-order' */
        '@/components/views/digitization/ViewDigitizationOrder'
      ),
  },
  {
    name: 'ViewCreateDigitizationOrder',
    path: '/create-digitization-order/:id',
    component: () =>
      import(
        /* webpackChunkName: 'view-create-digitization-order' */
        '@/components/views/digitization/ViewCreateDigitizationOrder'
      ),
  },
  {
    name: 'ViewManageDigitizationOrderItem',
    path: '/manage-digitization-order-item',
    component: () =>
      import(
        /* webpackChunkName: 'view-manage-digitization-order-item' */
        '@/components/views/digitization/view-manage-digitization-order-item/ViewManageDigitizationOrderItem'
      ),
  },
  {
    name: 'ViewDigitizedAssets',
    path: '/digitized',
    component: () =>
      import(
        /* webpackChunkName: 'view-digitized-assets' */
        '@/components/views/digitization/ViewDigitizedAssets'
      ),
  },
]

const fabricatorRoutes = [
  {
    name: 'ViewFabricatorTool',
    path: '/fabricator/:id',
    meta: darkThemeMeta,
    component: () => import(/* webpackChunkName: 'view-fabricator' */ '@/components/views/fabricator/ViewFabricator'),
    children: [
      {
        path: 'quick-generate',
      },
      {
        path: 'generate-swatches',
      },
      {
        path: 'upload-swatch',
      },
      {
        path: 'make-seamless',
      },
      {
        path: 'upscale-texture',
      },
      {
        path: 'generate-pbr-maps',
      },
    ].map((children) => ({
      ...children,
      component: {
        template: '',
      },
    })),
  },
  {
    path: '/fabricator-list',
    meta: darkThemeMeta,
    component: () =>
      import(/* webpackChunkName: 'view-fabricator-list' */ '@/components/views/fabricator/ViewFabricatorList'),
  },
]

export default [
  ...routes,
  ...stripeRoutes,
  ...myPageRoutes,
  ...myAssetsRoutes,
  ...digitizationOrdersRoutes,
  ...fabricatorRoutes,
].map((o) => {
  o.beforeEnter = authCheck

  if (!o.meta) o.meta = { $$auth: true }
  else o.meta.$$auth = true

  return o
})
